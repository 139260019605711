module.exports = [{
      plugin: require('/codebuild/output/src976808640/src/bitbucket.org/teamgeek/submitt-admin/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src976808640/src/bitbucket.org/teamgeek/submitt-admin/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src976808640/src/bitbucket.org/teamgeek/submitt-admin/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
