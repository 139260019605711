import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-boost"
import { Auth } from "aws-amplify"

import introspectionQueryResultData from "./fragmentTypes.json"

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.GRAPHQL_URI}`,
    fetch: async (uri, options) => {
      const session = await Auth.currentSession()
      options.headers["Authorization"] = session.idToken.jwtToken
      return fetch(uri, options)
    },
  }),
  cache: new InMemoryCache({ fragmentMatcher }),
})

export default client
